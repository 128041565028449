import * as _typeDetect2 from "type-detect";

var _typeDetect = "default" in _typeDetect2 ? _typeDetect2.default : _typeDetect2;

var exports = {};
var type = _typeDetect;
/**
 * Returns the lower-case result of running type from type-detect on the value
 *
 * @param  {*} value
 * @returns {string}
 */

exports = function typeOf(value) {
  return type(value).toLowerCase();
};

export default exports;