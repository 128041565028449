import _array from "./array";
import _function from "./function";
import _map from "./map";
import _object from "./object";
import _set from "./set";
import _string from "./string";
var exports = {};
exports = {
  array: _array,
  function: _function,
  map: _map,
  object: _object,
  set: _set,
  string: _string
};
export default exports;