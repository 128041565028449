var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * A reference to the global object
 *
 * @type {object} globalObject
 */
var globalObject;
/* istanbul ignore else */

if (typeof _global !== "undefined") {
  // Node
  globalObject = _global;
} else if (typeof window !== "undefined") {
  // Browser
  globalObject = window;
} else {
  // WebWorker
  globalObject = self;
}

exports = globalObject;
export default exports;