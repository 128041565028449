import _global2 from "./global";
import _calledInOrder from "./called-in-order";
import _className from "./class-name";
import _deprecated from "./deprecated";
import _every from "./every";
import _functionName from "./function-name";
import _orderByFirstCall from "./order-by-first-call";
import _prototypes from "./prototypes";
import _typeOf from "./type-of";
import _valueToString from "./value-to-string";
var exports = {};
exports = {
  global: _global2,
  calledInOrder: _calledInOrder,
  className: _className,
  deprecated: _deprecated,
  every: _every,
  functionName: _functionName,
  orderByFirstCall: _orderByFirstCall,
  prototypes: _prototypes,
  typeOf: _typeOf,
  valueToString: _valueToString
};
export default exports;
const _global3 = exports.global;
export { _global3 as global };