var exports = {};

/**
 * Returns a string representation of the value
 *
 * @param  {*} value
 * @returns {string}
 */
function valueToString(value) {
  if (value && value.toString) {
    // eslint-disable-next-line @sinonjs/no-prototype-methods/no-prototype-methods
    return value.toString();
  }

  return String(value);
}

exports = valueToString;
export default exports;